import React from 'react'
import './header.scss'

const Header = () => {
    return (
        <div id='header' style={{ width: '100vw' }}>
            <div className='container'>
                <h1 className='intro'>
                    hi i'm julie 🙋‍♀️
                </h1>
            </div>
        </div>
    )
}

export default Header